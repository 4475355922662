import React, { Component } from 'react';
import InputCode from '../../../../components/inputCode/InputCode';

import '../../../../static/css/bootstrap-grid.css'
import './step.css'
import Loading from '../../../../components/loading/Loading';
import editIcon from '../../../../static/img/edit.svg';
import successIcon from '../../../../static/img/success.svg';

class EmailStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openWindow: "email",
            code: "",
            codeStatus: "",
            loadingEmail: false,
            loadingCode: false,
            error: "",
        }
    }

    getMailCode = async () => {
        this.setState({ loadingEmail: true });
        const api_url = await fetch(`user/getCodeMail/1/`, {
            method: 'post',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            },
            body: "email=" + this.props.UserInfo.email
        });
        const data = await api_url.json();

        this.setState({ loadingEmail: false });

        if (data['result']) {
            this.setState({ openWindow: "codeMail", error: "" })
            return;
        }

        this.setState({ error: "Email is already used" })
    }

    accessCode = async () => {
        this.setState({ loadingCode: true })
        const api_url = await fetch(`user/ifCodeMail/`, {
            method: 'post',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            },
            body: "email=" + this.props.UserInfo.email + "&code=" + this.state.code
        });
        const data = await api_url.json();

        this.setState({ loadingCode: false })
        this.setState({ codeStatus: data['result'] });
        if (data['result']) {
            this.setState({ codeStatus: data['result'] });
            let info = this.props.UserInfo;
            info.codeMail = this.state.code;
            // info.step = "Phone"
            this.props.UpdateUser(info)
        }
    }

    handleValidateEmail = (str) => {
        const emailRegex = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/);

        return emailRegex.test(str);
    }

    handleChageEmail = () => {
        this.setState({ openWindow: "email", loadingCode: false, codeStatus: "", code: "" });
    }

    render() {
        if (this.state.openWindow === "email") {
            return (
                <div className='StepBlock' style={{ height: "100%" }}>
                    <div className='StepBlockHeader'>
                        <div className='stepNumber d-none d-md-block'>Step {this.props.Steps.indexOf(this.props.SetStep) + 1}<span>/{this.props.Steps.length}</span></div>
                        <h2>Email verification</h2>
                        <p>We’ll use your email to share important information about your account with you</p>
                    </div>
                    <div className='StepBlockMain'>
                        <div className='row'>
                            <div className='col-12'>
                                <p>Email adress</p>
                                <input style={(this.handleValidateEmail(this.props.UserInfo.email) && this.state.error.length === 0) ? { border: "1px solid #4FB54D" } : {}} maxLength="40" value={this.props.UserInfo.email} name="mail" type="email" placeholder="Enter your email adress" onChange={(evt) => {
                                    let info = this.props.UserInfo;
                                    info.email = evt.target.value;
                                    this.props.UpdateDataUser(info)
                                }}></input>
                                <span className='error'>{this.state.error}</span>
                            </div>
                        </div>
                        <div className='row justify-content-center justify-content-md-end'>
                            {this.state.loadingEmail === true ? <div className='my-4'><Loading /></div> : <button disabled={this.props.UserInfo.email === "" || !this.handleValidateEmail(this.props.UserInfo.email)} className='submitButtonStepStyle' onClick={() => this.getMailCode()}>Verify</button>}
                        </div>
                    </div>
                </div>
            );
        }

        if (this.state.openWindow === "codeMail") {
            return (
                <div className='StepBlock' style={{ height: "100%" }}>
                    <div className='StepBlockHeader'>
                        <div className='StepBlockHeaderColumn'>
                            <div className='stepNumber'>Step {this.props.Steps.indexOf(this.props.SetStep) + 1}<span>/{this.props.Steps.length}</span></div>
                            <h2>Email verification</h2>
                            <p>Check your inbox - we’ve sent a verification code to <b>{this.props.UserInfo.email}</b></p>
                            <div className='edit-row'>
                                <img src={editIcon} alt='edit'></img>
                                <span onClick={this.handleChageEmail} className='edit-text'>change</span>
                            </div>
                        </div>
                    </div>
                    <div className='StepBlockMain'>
                        <div className='row'>
                            <div className='col-12'>
                                <p>Please enter the code sent to verify your email address</p>
                                <InputCode codeStatus={this.state.codeStatus} code={this.state.code} setCode={(evt) => this.setState({ code: evt })} />
                                {this.state.codeStatus === false && <span className='incorrect-code'>Incorrect code</span>}

                                {(this.state.codeStatus !== true && this.state.loadingCode === false) && <div onClick={this.getMailCode} className='resend-code'>Resend code</div>}

                                {this.state.loadingCode === true && <div className='my-4'>
                                    <Loading />
                                    <div className='checking-code'>Checking the code</div>
                                </div>}

                                {this.state.codeStatus === true && <div className='my-4'>
                                    <img src={successIcon} alt='success'></img>
                                    <div className='success'>Success!</div>
                                </div>}
                            </div>
                        </div>
                        <div className='row' style={{ justifyContent: "right" }}>
                            <button disabled={this.state.code.length < 6} className='submitButtonStepStyle' onClick={
                                () => {
                                    if (this.state.codeStatus === true) {
                                        let info = this.props.UserInfo;
                                        info.codeMail = this.state.code;
                                        info.step = "Phone";
                                        this.props.UpdateUser(info);
                                    } else {
                                        this.accessCode();
                                    }
                                }
                            }>{this.state.codeStatus === true ? "Next step" : "Submit"}</button>
                        </div>
                    </div>
                </div>
            );
        }

    }
}
export default EmailStep;