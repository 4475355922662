import React, { Component } from 'react';
import '../../../../static/css/bootstrap-grid.css'
import './step.css'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';

class SubmitStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            check: false
        }
    }

    submitUser = async () => {
        let info = this.props.UserInfo;
        info.regType = this.props.regType;
        info.conditions = this.state.check;
        info.step = "Login";
        console.log(info);
        await this.props.UpdateUser(info);
        this.props.history.push("/exchange");
    }

    render() {
        return (
            <div className='StepBlock' style={{ height: "100%" }}>
                <div className='StepBlockHeader'>
                    <div className='stepNumber d-none d-md-flex justify-content-md-between'>
                        <p>Step {this.props.Steps.indexOf(this.props.SetStep) + 1}<span>/{this.props.Steps.length}</span></p>
                        <p>Application form</p>
                    </div>
                    <h2>Terms and conditions</h2>
                    <p>Your application is almost complete!</p>
                    <p>Please review the following terms and conditions before we create your account.</p>
                </div>
                <div className='StepBlockMain'>
                    <div className='row'>
                        <div className='col-12'>
                            <ul>
                                <li>I declare that I act on my own behalf.</li>
                                <li>I confirm that my income and assets are sufficient to initiate and complete the transactions I request.</li>
                                <li>I consent to the use of electronic forms for all documents pertaining to transactions and my relationship with WhatMoney INC, foregoing paper documents.</li>
                                <li>I confirm that I have reviewed, understand, and agree to the rules, regulations, and fees associated with my transactions. I authorize WhatMoney INC to process my requests and conduct transactions in the state of Florida, adhering to applicable laws and regulations.</li>
                                <li>I permit WhatMoney INC to collect, process, and, when necessary, disclose my personal data to regulatory authorities as part of transaction execution. I attest to the truthfulness of the information I have provided.</li>
                            </ul>
                        </div>
                        <div>
                            <p style={{ fontWeight: 400, fontSize: 12, lineHeight: 1.5, color: "#807C90" }}>*By signing this application, I acknowledge and accept all potential risks associated with the processing of my application by MWC company. This includes risks related to transaction banks, blockchain technology, digital wallets, cryptocurrencies, payment systems, issuers of fiat and cryptocurrencies, crypto exchanges, among others, which lie outside the MWC company's responsibility.</p>
                            <p style={{ fontWeight: 400, fontSize: 12, lineHeight: 1.5, color: "#807C90", borderBottom: "1px solid #CECED3", paddingBottom: 20 }}>*To assist in the prevention of terrorism financing and money laundering, federal law mandates that financial institutions obtain, verify, and record information identifying each person who conducts transactions with MWC.</p>
                            <div className='submitInputBlock'>
                                <div className='submitInputWrapper'>
                                    <input onChange={(evt) => { this.setState({ check: !this.state.check }); let info = this.props.UserInfo; info.conditions = !this.state.check; this.props.UpdateDataUser(info) }} type="checkbox" style={{ width: "24px", height: "24px", border: "2px solid #090909" }} />
                                </div>
                                <p style={{ marginLeft: 15 }}>I have read and agreed to the above terms of my application.</p>
                            </div>
                        </div>
                    </div>
                    <div className='row' style={{ justifyContent: "space-between", alignItems: "center" }}>
                        <div className='personal__bottom-text d-none d-md-block'>*To apply, please complete this form in English.</div>
                        <button
                            disabled={!this.state.check}
                            className='submitButtonStepStyle'
                            onClick={this.submitUser}>Submit</button>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(SubmitStep);