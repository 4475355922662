import React, { Component } from 'react';
import '../../../../static/css/bootstrap-grid.css'
import './step.css'

class InitialsStep extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    handleValidateInput = (str) => {
        const invalidChars = new RegExp('[!@#$%^&*()_+={}\\[\\]\\|:;"\'<>,.?/~`0-9]');

        if (invalidChars.test(str)) {
            return false;
        } else if (invalidChars.test(str) == false && str.length > 0) {
            return true;
        }

        return null;
    }

    render() {
        return (
            <div className='StepBlock' style={{ height: "100%" }}>
                <div className='StepBlockHeader'>
                    <div className='stepNumber d-none d-md-block'>Step {this.props.Steps.indexOf(this.props.SetStep) + 1}<span>/{this.props.Steps.length}</span></div>
                    <h2>Your full name</h2>
                    <p>This name will be linked to your account</p>
                </div>
                <div className='StepBlockMain'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12'>
                            <p>First name</p>
                            <input style={this.handleValidateInput(this.props.UserInfo.firstName) !== null ? {
                                border: `1px solid ${this.handleValidateInput(this.props.UserInfo.firstName) ? "#4FB54D" : "#DE5656"}`,
                            } : {}} maxLength="40" type="text" placeholder="Enter your first name" onChange={(evt) => {
                                let info = this.props.UserInfo;
                                info.firstName = evt.target.value.trim();
                                this.props.UpdateDataUser(info)
                            }}></input>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12'>
                            <p>Last name</p>
                            <input style={this.handleValidateInput(this.props.UserInfo.lastName) !== null ? {
                                border: `1px solid ${this.handleValidateInput(this.props.UserInfo.lastName) ? "#4FB54D" : "#DE5656"}`,
                            } : {}} maxLength="40" type="text" placeholder="Enter your last name" onChange={(evt) => {
                                let info = this.props.UserInfo;
                                info.lastName = evt.target.value.trim();
                                this.props.UpdateDataUser(info);
                            }}></input>
                        </div>
                    </div>
                    <div className='row' style={{ justifyContent: "right" }}>
                        <button disabled={this.props.UserInfo.firstName === "" || this.props.UserInfo.lastName === "" || this.handleValidateInput(this.props.UserInfo.firstName) === false || this.handleValidateInput(this.props.UserInfo.lastName) === false} className='submitButtonStepStyle' onClick={() => { let info = this.props.UserInfo; info.step = 'Email'; this.props.UpdateDataUser(info) }}>Next step</button>
                    </div>
                </div>
            </div>
        );
    }
}
export default InitialsStep;