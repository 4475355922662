import React, { Component } from 'react';
import { nationalitiesList } from '../../../../static/constants/nationalitiesList';
import './countryList.css';

export default class CountryList extends Component {
  list =  [...nationalitiesList.slice(0, 1), ...nationalitiesList.slice(1)]
  constructor(props) {
    super(props);
  }

  hasValueWithSubstring(arr, substring) {
    return arr.some(item => {
      const chosenItem = this.props.mode === "country" ? item.en_short_name : item.nationality;

      return typeof chosenItem === 'string' && chosenItem.toLowerCase().includes(substring.toLowerCase());
    });
  }

  render() {
    return (
      <>
        {this.hasValueWithSubstring(this.list, this.props.country) && <div onMouseDown={this.props.preventBlur} className='countries-list'>
          <div className='countries-list__container'>
            {this.list
              .sort((a, b) => a - b)
              .map(({ num_code, alpha_2_code, en_short_name, nationality }) => {
                const chosenItem = this.props.mode === "country" ? en_short_name : nationality;

                if (chosenItem.toLowerCase().includes(this.props.country.toLowerCase())) {
                  return <div key={num_code} onClick={() => {
                    this.props.setCountry(chosenItem)
                  }} className='country__container'>
                    <img
                      alt={en_short_name}
                      src={`https://flagcdn.com/16x12/${alpha_2_code.toLowerCase()}.png`}
                    />
                    <p className='country__name'>{chosenItem}</p>
                  </div>
                }
              })
            }
          </div>
        </div>}
      </>
    )
  }
}
