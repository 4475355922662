import React, { Component } from 'react'

export default class ExchangeHistoryDetails extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { title, subtitle } = this.props;

    return (
      <div className='exchange-history__details__text'>
        <p className='exchange-history__details_title'>{title}</p>
        <p className='exchange-history__details_subtitle'>{subtitle}</p>
      </div>
    )
  }
}
