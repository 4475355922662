import React, { Component } from 'react'
import './exchangeHistory.css';
import cross from '../../static/img/cross.svg';
import exit from '../../static/img/exit.svg';
import ExchangeHistoryItem from './exchangeHistoryItem';
import chevronLeft from '../../static/img/chevron-left.svg';
import chevronDown from '../../static/img/chevron-down.svg';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import '../../static/css/bootstrap-grid.css';
import ExchangeHistoryFilters from './exchangeHistoryFilters';
import ExchangeHistoryDetails from './exchangeHistoryDetails';
import { formatCountToString } from '../../utils/priceFunctions';

TimeAgo.addDefaultLocale(en);

export default class ExchangeHistory extends Component {

  constructor(props) {
    super(props);
    this.dayAgo = "";
    this.historyArr = [];
    this.state = {
      settingsRef: React.createRef(null),
      exchangeHistoryRef: React.createRef(null),
      isSettingsOpen: false,
      user: null,
      page: "history",
      filteredArr: [],
      isDateOpen: false,
      isStatusOpen: false,
      isCurrencyOpen: false,
      chosenDate: "",
      chosenStatus: "",
      chosenCurrency: "",
      chosenDetails: null,
      disableClickOutside: false,
      setSecPass: ""
    }
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  showTimeAgo = (date) => {
    const timeAgo = new TimeAgo('en-US');
    const now = new Date();
    const givenDate = new Date(date);
    const differenceInMillis = now - givenDate;
    const oneDayInMillis = 24 * 60 * 60 * 1000;


    const result = differenceInMillis <= oneDayInMillis ? "Today" : timeAgo.format(givenDate);


    if (this.dayAgo === result) {
      return;
    }

    this.dayAgo = result;

    return result;
  }

  isLogin = async () => {
    const api_url = await fetch(`user/islogin/`, {
      method: 'post',
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      }
    });
    const data = await api_url.json();

    if (data['result']) {
      this.setState({ user: data["user"] })
    }
  }

  loadHistory = async () => {
    const api_url = await fetch('user/getHistory');
    const data = await api_url.json();


    if (data['result']) {
      this.historyArr = data["history"].reverse();
      this.setState({ filteredArr: data["history"] });
    }
  }


  formatDate(date) {
    return new Date(date).toLocaleDateString('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  }

  formatTime(date) {
    return new Date(date).toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  }

  filterLastDays(arr, period) {
    const now = new Date();
    let daysNum = 1;

    switch (period) {
      case "Today":
        daysNum = 1;
        break;
      case "Last 7 days":
        daysNum = 7;
        break;
      case "Last 30 days":
        daysNum = 30;
        break;
      case "Last 90 days":
        daysNum = 90;
        break;
      case "Last year":
        daysNum = 365;
        break;
      default:
        daysNum = 1;
    }

    const daysAgo = new Date(now.setDate(now.getDate() - daysNum));

    const filtered = arr.filter(item => {
      const date = new Date(item.datetimeCreate);
      return date >= daysAgo && date <= new Date();
    });


    return filtered;
  }

  filterCurrency = (arr, currency) => {
    let filteredArr = [];
    if (currency === "USD → USDT") {
      filteredArr = arr.filter(item => item.input.name === "USD");
      return filteredArr;
    }
    filteredArr = arr.filter(item => item.input.name === "USDT");

    return filteredArr;
  }

  filterStatus = (arr, status) => {
    const filteredArr = arr.filter(item => item.status.toLowerCase() === status.toLowerCase());

    return filteredArr;
  }

  handleCrossClick = () => {
    this.props.onClose(() => this.setState({
      page: "history",
      isDateOpen: false,
      isStatusOpen: false,
      isCurrencyOpen: false,
      chosenDate: "",
      chosenStatus: "",
      chosenCurrency: "",
      chosenDetails: null,
    }));
  }

  handleLogoutClick = () => {
    document.cookie = "session=";
    document.location = "/";
  }

  handleClickOutside = (evt) => {
    if (this.state.isSettingsOpen && this.state.settingsRef && this.state.settingsRef.current && !this.state.settingsRef.current.contains(evt.target)) {
      this.setState({ isSettingsOpen: false });
    }

    if (this.state.disableClickOutside) {
      this.setState({ disableClickOutside: false });
      return;
    }

    if (window.innerWidth >= 768 && this.props.isAllowedToCloseHistory && !(this.state.exchangeHistoryRef.current && this.state.exchangeHistoryRef.current.contains(evt.target))) {
      this.props.onClose(this.handleCrossClick);
    }
  }

  calculateFeePercent = (fee, price) => {
    return fee * 100 / price
  }

  handleUpdateFilters = () => {
    let arr = [...this.historyArr];

    if (this.state.chosenDate !== "") {
      arr = this.filterLastDays(arr, this.state.chosenDate);
    }

    if (this.state.chosenCurrency !== "") {
      arr = this.filterCurrency(arr, this.state.chosenCurrency);
    }

    if (this.state.chosenStatus !== "") {
      arr = this.filterStatus(arr, this.state.chosenStatus);
    }

    this.setState({ filteredArr: arr });
    this.dayAgo = "";
  }

  componentDidMount() {
    this.isLogin();

    document.body.addEventListener("click", this.handleClickOutside);

    return () => {
      document.body.addEventListener("click", this.handleClickOutside)
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isOpened !== this.props.isOpened) {
      this.props.isOpened ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'auto';
    }

    if (prevProps.User && prevProps.User["auth"] !== this.props.User["auth"]) {
      this.loadHistory();
    }

    if (prevProps.isOpened !== this.props.isOpened && this.props.isOpened === true) {
      this.loadHistory();
    }

    if (prevState.chosenDate !== this.state.chosenDate || prevState.chosenStatus !== this.state.chosenStatus || prevState.chosenCurrency !== this.state.chosenCurrency) {
      this.handleUpdateFilters();
    }
  }

  // getSecPass = async (id) => {
  //   const api_url = await fetch(`user/setSecPass/`, {
  //     method: 'post',
  //     headers: {
  //       "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
  //     },
  //     body: 'id=' + id
  //   });
  //   const data = await api_url.json();
  //   if (data['result']) {
  //     this.setState({ setSecPass: data['secPass'] })
  //   }
  // }


  render() {
    return (

      <div ref={this.state.exchangeHistoryRef} className={`exchange-history ${this.props.isOpened ? "open" : ""}`}>
        {this.state.page === "history" && <div className='container exchange-history__container'>

          {/* Desktop header */}
          <div className='exchange-history__header'>
            {this.props.User["auth"] && <div className='exchange-history__text'>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} className='nameAuthUserHeaderShort'>{this.props.User["firstName"][0]}{this.props.User["lastName"][0]}</div>
              <div ref={this.state.settingsRef} className='exchange-history__profile' style={{ position: "relative" }}>
                <div className='nameAuthUserHeader'>{this.props.User["firstName"]} {this.props.User["lastName"]}</div>
                <img onClick={() => this.setState({ isSettingsOpen: true })} src={chevronDown} alt='chevronDown' style={{ cursor: "pointer" }}></img>
                {this.state.isSettingsOpen && <div onClick={this.handleLogoutClick} className='exchange__profile-dropdown'>
                  <img src={exit} alt='exit'></img>
                  <div className='profile-dropdown__text'>Log Out</div>
                </div>}
              </div>
            </div>}
            <img onClick={this.handleCrossClick} src={cross} alt='cross' className='cross'></img>
          </div>
          <h1 className='d-none d-md-block'>Exchanges History</h1>

          {/* Mobile header */}
          <div className='d-flex d-md-none justify-content-between align-items-center' style={{ marginTop: "30px" }}>
            <h1>Exchanges History</h1>
            <img onClick={this.handleCrossClick} src={cross} alt='cross' style={{ cursor: "pointer" }}></img>
          </div>
          <ExchangeHistoryFilters
            onClick={() => this.setState({ disableClickOutside: true })}
            chosenDate={this.state.chosenDate}
            setChosenDate={(value) => this.setState({ chosenDate: value })}
            chosenCurrency={this.state.chosenCurrency}
            setChosenCurrency={(value) => this.setState({ chosenCurrency: value })}
            chosenStatus={this.state.chosenStatus}
            setChosenStatus={(value) => this.setState({ chosenStatus: value })}
          />
          <div className='exchange-history__items'>
            {this.state.filteredArr.map(({ id, fee, input, output, status, datetimeCreate, secPass }, index) => {
              const timeAgo = this.showTimeAgo(datetimeCreate);

              return <div key={id}>
                {timeAgo && <div className='exchange-history__time-ago'>{timeAgo}</div>}
                <ExchangeHistoryItem
                  id={id}
                  fee={formatCountToString(this.calculateFeePercent(fee, input.count))}
                  input={input}
                  output={output}
                  status={status}
                  date={this.formatDate(datetimeCreate)}
                  openDetails={() => { this.setState({ disableClickOutside: true, page: "details", chosenDetails: { id, secPass, fee: this.calculateFeePercent(fee, input.count), input, output, status, datetimeCreate } }) }}
                />
                {(index < this.state.filteredArr.length - 1) && <hr className='exchange-divider' />}
              </div>
            })}
          </div>
        </div>}


        {this.state.page === "details" && <div className='container exchange-history__container'>
          <div className='exchange-history__header'>
            {this.props.User["auth"] && <div className='exchange-history__text'>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} className='nameAuthUserHeaderShort'>{this.props.User["firstName"][0]}{this.props.User["lastName"][0]}</div>
              <div ref={this.state.settingsRef} className='exchange-history__profile' style={{ position: "relative" }}>
                <div className='nameAuthUserHeader'>{this.props.User["firstName"]} {this.props.User["lastName"]}</div>
                <img onClick={() => this.setState({ isSettingsOpen: true })} src={chevronDown} alt='chevronDown' style={{ cursor: "pointer" }}></img>
                {this.state.isSettingsOpen && <div onClick={this.handleLogoutClick} className='exchange__profile-dropdown'>
                  <img src={exit} alt='exit'></img>
                  <div className='profile-dropdown__text'>Log Out</div>
                </div>}
              </div>
            </div>}
            <img onClick={this.handleCrossClick} src={cross} alt='cross' className='cross'></img>
          </div>

          <div className='d-flex justify-content-between align-items-center' style={{ marginTop: "30px" }}>
            <div className='exchange-history__title'>
              <img onClick={() => this.setState({ disableClickOutside: true, page: "history" })} src={chevronLeft} alt='arrowleft' style={{ cursor: "pointer" }}></img>
              <h1 style={{ margin: "0" }}>Exchange Details</h1>
            </div>
            <img className='d-block d-md-none' onClick={this.handleCrossClick} src={cross} alt='cross' style={{ cursor: "pointer" }}></img>
          </div>
          <div className='exchange-history__details'>
            <ExchangeHistoryDetails title={"Order ID"} subtitle={this.state.chosenDetails.secPass} />
            <ExchangeHistoryDetails title={"Appointment Date"} subtitle={this.formatDate(this.state.chosenDetails.datetimeCreate)} />
            <ExchangeHistoryDetails title={"Appointment Time"} subtitle={this.formatTime(this.state.chosenDetails.datetimeCreate)} />
            <ExchangeHistoryDetails title={"Input Amount"} subtitle={`${formatCountToString(this.state.chosenDetails.input.count)} ${this.state.chosenDetails.input.name}`} />
            <ExchangeHistoryDetails title={"Fee"} subtitle={`${formatCountToString(this.state.chosenDetails.fee)}%`} />
            <ExchangeHistoryDetails title={"Output Amount"} subtitle={`${formatCountToString(this.state.chosenDetails.output.count)} ${this.state.chosenDetails.output.name}`} />

            <div className='exchange-history__details__text'>
              <p className='exchange-history__details_title'>Status</p>
              <div className='exchange-item__status'>
                <div className={`circle-status ${this.state.chosenDetails.status.toLowerCase()}`}></div>
                <p style={{ textTransform: "capitalize" }} className='exchange-history__details_subtitle'>{this.state.chosenDetails.status}</p>
              </div>
            </div>
          </div>
        </div>}
      </div>

    )
  }
}
