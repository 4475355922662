import React, { Component } from 'react';
import '../../static/css/bootstrap-grid.css'
import '../../core/core.css';
import eyeIcon from '../../static/img/eye.svg';
import eyeSlashIcon from '../../static/img/eye-slash.svg';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import errorIcon from '../../static/img/errorIcon.svg'

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            isSuccess: true,
            isVisible: false
        }
    }

    login = async () => {
        const api_url = await fetch(`user/login/`, {
            method: 'post',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            },
            body: "email=" + this.state.email + "&password=" + this.state.password
        });
        const data = await api_url.json();
        this.setState({ isSuccess: data['result'] });
        if (data['result']) {
            this.props.IsLogin()
        } else {
            console.log(data['messege'])
        }
    }

    componentDidMount = () => {
        if (this.props.User['auth']) {
            // this.props.history.goBack();
            this.props.history.push('/exchange');
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.User !== this.props.User) {
            if (this.props.User['auth']) {
                // this.props.history.goBack();
                this.props.history.push('/exchange');
            }
        }
    }

    render() {
        return (
            <div className='container loginContainer'>
                <div className='loginBlock'>
                    <div className='container'>
                        <div>
                            <h1 className='loginTitle'>Log In</h1>
                            <p className='loginSubtitle'>Log In to exchange USDT and USD</p>
                            {!this.state.isSuccess && <div className='login__status'>
                                <img src={errorIcon} alt="error"></img>
                                <p>Wrong email or password</p>
                            </div>}

                            <div className='inputLogin'>
                                <p>Email</p>
                                <input
                                    // style={{ border: `${this.state.isSuccess ? '1px solid #CECED3' : '1px solid #DE5656'}` }}
                                    maxLength="30" type="text"
                                    placeholder="Enter your email"
                                    onChange={(evt) => { this.setState({ email: evt.target.value.toLowerCase(), isSuccess: true }) }}>
                                </input>
                                {/* {!this.state.isSuccess && <span className='login__status'>Incorrect email</span>} */}
                            </div>

                            <div className='inputLogin' style={{ marginTop: "20px" }}>
                                <p>Password</p>
                                <div className='inputLogin__container'>
                                    <input
                                        // style={{ border: `${this.state.isSuccess ? '1px solid #CECED3' : '1px solid #DE5656'}` }}
                                        maxLength="30"
                                        type={`${this.state.isVisible ? "text" : "password"}`}
                                        placeholder="Enter your password"
                                        onChange={(evt) => { this.setState({ password: evt.target.value, isSuccess: true }) }}>
                                    </input>

                                    <img onClick={() => this.setState({ isVisible: !this.state.isVisible })} src={this.state.isVisible ? eyeSlashIcon : eyeIcon} alt='eye'></img>
                                    {/* {!this.state.isSuccess && <span className='login__status'>Passwords do not match</span>} */}

                                </div>
                            </div>

                            <div className='forgotPass'>
                                <Link to="/forgot-password"><p className='forgotPass__text'>Forgot password?</p></Link>
                            </div>
                        </div>
                        <div>
                            <button disabled={this.state.email === "" || this.state.password === ""} className='submitButtonLoginStyle' onClick={() => this.login()}>Login</button>
                            <Link to="/registration">
                                <button className='submitButtonSignupStyle'>Sign Up</button>
                            </Link>
                        </div>

                    </div>
                </div>
            </div >
        );
    }
}
export default withRouter(LoginPage);