import '../../static/css/bootstrap-grid.css'
import React, { Component } from 'react';
import '../../core/core.css'
import InputCurrency from './inputCurrency';
import logoUsd from "../../static/img/usd.png"
import logoExchange from "../../static/img/swap.png"
import PopupSuccess from '../../components/exchangePopupSuccess/PopupSuccess';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { formatCountToFloat, formatCountToString } from '../../utils/priceFunctions';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import { networksSelectArr } from '../../utils/selectArrays';
import CurrencySelectItem from '../../components/CurrencySelectItem/CurrencySelectItem';

class ExchangePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            wallet: "",
            networkObj: null,
            secPass: "",
            valueCryptoCurrrancy: 0.0,
            valueCurrancy: 0.0,
            setCryptoCurrancy: {
                "id": 0,
                "name": "USDT",
                "coefficient": 1,
                "comission": { "0": 5, "5000": 3 },
                "logo": logoUsd,
                "description": "5% fee for transactions < $5000  |  3% fee for transactions > $5000"
            },
            activeSettingCurrancy: '0',
            arrCryptoCurrancy: [],
            selectDate: "",
            selectTime: "",
            invert: false,
            isModalOpened: false,
            isHistoryOpened: false,
            modalData: {
                date: "",
                time: "",
                invert: false,
                valueCryptoCurrrancy: 0,
                valueCurrancy: 0
            },
            isDateOpen: false,
            isTimeOpen: false,
            timeInputValue: ""
        }
    }

    cryptoCurrencyToCurrency = (value = 0) => {
        let mainKey = Object.keys(this.state.setCryptoCurrancy["comission"])[0]
        Object.keys(this.state.setCryptoCurrancy["comission"]).forEach((element) => {
            if (Number(element) > Number(mainKey) && value > Number(element)) {
                mainKey = element
            }
        })
        // инверсия расчетов
        if (this.state.invert) {
            this.setState({ valueCryptoCurrrancy: value * (100 - this.state.setCryptoCurrancy["comission"][mainKey]) / 100, valueCurrancy: value, activeSettingCurrancy: mainKey })
        } else {
            this.setState({ valueCryptoCurrrancy: value, valueCurrancy: value * (100 - this.state.setCryptoCurrancy["comission"][mainKey]) / 100, activeSettingCurrancy: mainKey })
        }
    }

    getCryptoCurrancyData = async () => {
        const api_url = await fetch(`user/getCryptoCurrancy/`, {
            method: 'post',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            }
        });
        const data = await api_url.json();
        if (data['result'] && data['data'].length > 0) {
            this.setState({ arrCryptoCurrancy: data['data'], setCryptoCurrancy: data['data'][0] })
        }
    }

    createExchange = async () => {
        const api_url = await fetch(`user/createExchange/`, {
            method: 'post',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            },
            body: "data=" + JSON.stringify({ "count": this.state.invert ? formatCountToFloat(this.state.valueCurrancy) : formatCountToFloat(this.state.valueCryptoCurrrancy), "idCryptoCurrancy": this.state.setCryptoCurrancy["id"], "invert": this.state.invert, network: this.state.invert ? this.state.networkObj.text : "", wallet: this.state.invert ? this.state.wallet : "" })
        });
        const data = await api_url.json();

        this.setState({
            modalData: {
                date: this.state.selectDate.replace(/-/g, "/"),
                time: this.state.selectTime,
                invert: this.state.invert,
                valueCryptoCurrrancy: formatCountToString(this.state.valueCryptoCurrrancy),
                valueCurrancy: formatCountToString(this.state.valueCurrancy),
                name: this.state.setCryptoCurrancy["name"],
                logo: this.state.setCryptoCurrancy["logo"]
            }
        })


        if (data['result']) {
            this.setState({
                isModalOpened: true,
                secPass: data["secPass"],
                networkObj: null,
                wallet: "",
                valueCurrancy: 0,
                valueCryptoCurrrancy: 0,
            });
            this.updateHistoryCount();
        } else {
            alert(data['message'])
        }
    }

    updateHistoryCount = () => {
        this.props.setHistoryCount(this.props.historyCount + 1);
    }


    handleNetworkSelect({ image1, text }) {
        this.setState({ networkObj: { image1, text } });
    }

    handleExchangeValidation() {
        if (!this.state.invert) {
            return true;
        }

        if (!this.state.networkObj) {
            return false;
        }

        return this.state.networkObj.text && this.state.wallet;
    }

    componentDidMount = () => {
        this.getCryptoCurrancyData();
        if (!this.props.User['auth']) {
            this.props.history.push('/');
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.User !== this.props.User) {
            if (!this.props.User['auth']) {
                this.props.history.push('/');
            }
        }

        if (prevState.invert !== this.state.invert) {
            this.setState({
                networkObj: null,
                wallet: "",
            })
        }
    }

    render() {
        return (
            <div className='mainContainer' style={{ height: "initial" }}>
                {this.state.isModalOpened && <PopupSuccess
                    secPass={this.state.secPass}
                    openHistory={() => {
                        this.props.openHistory();
                        this.setState({ isModalOpened: false });
                    }}
                    data={this.state.modalData}
                    closePopup={() => this.setState({ isModalOpened: false })} />}

                <div className='exchangeBlock' style={{ marginBottom: "50px" }}>
                    <div className='container'>
                        <h1 style={{ marginTop: 0 }}>Exchange</h1>

                        {this.state.invert ? <InputCurrency invert={this.state.invert} Header={"You Pay"} Active={true} LogoCurrancy={logoUsd} SetValue={(value) => {
                            this.cryptoCurrencyToCurrency(value);
                        }} InitValue={this.state.valueCurrancy} /> : <InputCurrency invert={this.state.invert} Header={"You Pay"} Active={true} LogoCurrancy={this.state.setCryptoCurrancy["logo"]} SetValue={(value) => {
                            this.cryptoCurrencyToCurrency(value)
                        }} InitValue={this.state.valueCryptoCurrrancy} />}


                        <div className='iconExchange__container'>
                            <img className='iconExchange' onClick={() => this.setState({ invert: !this.state.invert })} src={logoExchange} alt='icon' />
                        </div>


                        {this.state.invert ? <InputCurrency invert={this.state.invert} Header={"You Receive"} Active={false} LogoCurrancy={this.state.setCryptoCurrancy["logo"]} SetValue={(value) => this.cryptoCurrencyToCurrency(value)} InitValue={this.state.valueCryptoCurrrancy} /> : <InputCurrency invert={this.state.invert} Header={"You Receive"} Active={false} LogoCurrancy={logoUsd} SetValue={(value) => this.cryptoCurrencyToCurrency(value)} InitValue={this.state.valueCurrancy} />}

                        {this.state.invert && <div>
                            <div className='inputLogin mt-4 mb-4'>
                                <label>
                                    <p>USDT Wallet</p>
                                    <input placeholder='Enter your USDT wallet ' value={this.state.wallet} onChange={(e) => this.setState({ wallet: e.target.value })}></input>
                                </label>
                            </div>

                            <CustomSelect
                                label={"Network"}
                                placeholder="Select network"
                                value={this.state.networkObj}
                                options={networksSelectArr.map(({ image1, text }, index) => {
                                    return (
                                        <CurrencySelectItem
                                            key={index}
                                            image1={image1}
                                            text={text}
                                            isChosen={networksSelectArr?.text === text}
                                            onClick={() => this.handleNetworkSelect({ image1, text })}
                                        />
                                    );
                                })}
                            />
                        </div>}

                        <div className='d-flex flex-row align-items-center justify-content-between py-1 mt-4'>
                            <div className='pr-3'>
                                <div className='fee'>Transaction Fee ({this.state.setCryptoCurrancy["comission"][this.state.activeSettingCurrancy]}%)</div>
                                <div className='descriptionExchange'>{this.state.setCryptoCurrancy["description"]}</div>
                            </div>
                            <div className='feecount'>${this.state.invert ? formatCountToString(this.state.valueCurrancy * this.state.setCryptoCurrancy["comission"][this.state.activeSettingCurrancy] / 100) : formatCountToString(this.state.valueCryptoCurrrancy * this.state.setCryptoCurrancy["comission"][this.state.activeSettingCurrancy] / 100)}</div>
                        </div>


                        {/* <div className='setDatetimeInput'>
                            <p>Select the date you will visit us</p>
                            <CustomDatePicker isExchange={true} onChange={(date) => {
                                this.setState({ selectDate: date })
                            }} />
                            <p>Select the time you will visit us</p>
                            <CustomTimePicker day={this.state.selectDate} onChange={(date) => this.setState({ selectTime: date })} />
                        </div> */}
                        <button style={{ marginTop: 40 }} disabled={!this.handleExchangeValidation() || this.state.valueCryptoCurrrancy === 0} className='submitButtonLoginStyle' onClick={() => {
                            this.createExchange();
                        }}>Submit</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ExchangePage);