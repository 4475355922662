import React, { Component } from 'react';
import '../../../../static/css/bootstrap-grid.css'
import './step.css'
import PasswordStrengthIndicator from '../../../../components/passwordStrengthIndicator/PasswordStrengthIndicator';
import eyeIcon from '../../../../static/img/eye.svg';
import eyeSlashIcon from '../../../../static/img/eye-slash.svg';

class PasswordStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmPassword: "",
            isVisiblePassword: false,
            isVisibleRepeat: false,
            passwordStrength: 0,
        }
    }


    validatePassword = (password) => {
        const hasUppercase = /[A-Z]/.test(password);
        const hasNumber = /[0-9]/.test(password);
        const minLength = password.length >= 9;
        return hasUppercase && hasNumber && minLength;
    };


    render() {
        return (
            <div className='StepBlock' style={{ height: "100%" }}>
                <div className='StepBlockHeader'>
                    <div className='stepNumber d-none d-md-flex justify-content-md-between'>
                        <p>Step {this.props.Steps.indexOf(this.props.SetStep) + 1}<span>/{this.props.Steps.length}</span></p>
                        <p>Application form</p>
                    </div>
                    <h2>Create your password</h2>
                    <p>Secure your details with a strong and unique password</p>
                </div>
                <div className='StepBlockMain'>
                    <div className='row' style={{ paddingBottom: '30px' }}>

                        <div className='col-12'>
                            <p className='step__input__title_first-name' style={{ marginBottom: "12px" }}>Your password</p>
                            {/* <p style={{ fontSize: "12px", marginTop: "0px", marginBottom: "18px" }}>The password must include at least one uppercase letter and one number, and be a minimum of 9 characters in length.</p> */}

                            <div className='password-input'>
                                <input maxLength="40" value={this.props.UserInfo.password} type={`${this.state.isVisiblePassword ? "text" : "password"}`} placeholder="Password" onChange={(evt) => {
                                    let info = this.props.UserInfo;
                                    info.password = evt.target.value.trim();

                                    this.props.UpdateDataUser(info)
                                }}></input>
                                <img onClick={() => this.setState({ isVisiblePassword: !this.state.isVisiblePassword })} src={this.state.isVisiblePassword ? eyeSlashIcon : eyeIcon} alt='eye'></img>
                            </div>

                            <PasswordStrengthIndicator setPasswordStrength={(value) => this.setState({ passwordStrength: value })} password={this.props.UserInfo.password} />
                        </div>
                        <div className='col-12'>
                            <p className='step__input__title'>Confirm password</p>
                            <div className='password-input'>
                                <input maxLength="40" type={`${this.state.isVisibleRepeat ? "text" : "password"}`} placeholder="Password" value={this.state.confirmPassword} onChange={(evt) => this.setState({ confirmPassword: evt.target.value.trim() })}></input>
                                <img onClick={() => this.setState({ isVisibleRepeat: !this.state.isVisibleRepeat })} src={this.state.isVisibleRepeat ? eyeSlashIcon : eyeIcon} alt='eye'></img>
                            </div>
                        </div>
                    </div>
                    <div className='row' style={{ justifyContent: "space-between", alignItems: "center" }}>
                        <div className='personal__bottom-text d-none d-md-block'>*To apply, please complete this form in English.</div>
                        <button disabled={!this.validatePassword(this.props.UserInfo.password) || this.state.passwordStrength < 1 || this.props.UserInfo.password.length < 9 || this.props.UserInfo.password !== this.state.confirmPassword} className='submitButtonStepStyle' onClick={() => { let info = this.props.UserInfo; info.step = this.props.Steps[this.props.Steps.indexOf(this.props.UserInfo.step) + 1]; console.log(info); this.props.UpdateUser(info) }}>Next step</button>
                    </div>
                </div>
            </div>
        );
    }
}
export default PasswordStep;