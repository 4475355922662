import React, { Component } from 'react';
import './privacyPolicy.css';
import '../../static/css/bootstrap-grid.css';
import backIcon from '../../static/img/back.png';

class PrivacyPolicyPage extends Component {
    render() {
        return (
            <div className='container'>
                {/* <div className='backButtonPrivacy' onClick={() => this.props.Back()}><img src={backIcon} style={{ height: 15, cursor: "pointer" }} />Back</div> */}
                <div className='policy'>
                    <div>
                        <h1 className='policy__title'>Privacy Policy</h1>
                        <p className='policy__date'>Effective Date: 05.07.24</p>
                    </div>
                    <div className='policy__rules'>
                        <div>
                            <h2 className='policy__rules_main-item'>1. Introduction</h2>
                            <p>Welcome to WhatMoney INC ("WhatMoney INC", "we", "us", "our"). WhatMoney INC, based in Miami, FL, specializes in currency exchange services. This Privacy Policy is designed to inform you about our practices regarding the collection, use, and disclosure of information that you may provide via our services.
                            </p>
                        </div>
                        <div>
                            <h2 className='policy__rules_main-item'>2. Information We Collect</h2>
                            <ul>
                                <li>Personal Information: This includes, but is not limited to, your name, address, email address, telephone number, and financial information related to currency transactions.</li>
                                <li>Transaction Information: Details of the currency exchange transactions you carry out through our services, including amounts, currencies involved, and transaction dates.</li>
                                <li>Usage and Log Information: Information about how you use our services, including IP addresses, browser types, language settings, and timestamps.</li>
                            </ul>
                        </div>
                        <div>
                            <h2>3. How We Use Your Information</h2>
                            <ul>
                                <li>To Provide Services: We use the information we collect to operate, maintain, and provide the features and functionality of our currency exchange services.</li>
                                <li>For Communication: To communicate with you regarding your account and our services, including updates, security alerts, and support messages.</li>
                                <li>For Improvement and Development: To better understand how users access and use our services, both on an aggregated and individualized basis, in order to improve our services and respond to user desires and preferences.</li>
                            </ul>
                        </div>
                        <div>
                            <h2 className='policy__rules_item'>4. Disclosure of Your Information</h2>
                            <ul>
                                <li>To Service Providers: We may share your information with third-party service providers that assist us in providing the service.</li>
                                <li>Compliance and Safety: We may disclose personal information when required by law or when necessary to protect our rights, comply with legal proceedings, or enforce our policies.</li>
                            </ul>
                        </div>
                        <div>
                            <h2 className='policy__rules_item'>5. Data Security</h2>
                            <p>We employ appropriate technical and organizational measures designed to protect the information we collect from and about you from unauthorized access, use, or disclosure.</p>
                        </div>
                        <div>
                            <h2 className='policy__rules_item'>6. Your Rights</h2>
                            <p>You have the right to access, correct, or delete your personal data held by WhatMoney INC. You can also object to or restrict the processing of your personal data in certain circumstances.</p>
                        </div>
                        <div>
                            <h2 className='policy__rules_item'>7. Changes to This Privacy Policy</h2>
                            <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Effective Date" at the top of this policy.</p>
                        </div>
                        <div>
                            <h2 className='policy__rules_item'>8. Contact Us</h2>
                            <p>If you have any questions about this Privacy Policy, please contact us at: <b>contact@what-money.miami</b></p>
                        </div>
                        <div>
                            <p>By using WhatMoney INC services, you acknowledge that you have read and understand this Privacy Policy.</p>
                            <p className='mt-4 mb-2'>Registration address:</p>
                            <div className='policy__info mb-4'>
                                <p>WhatMoney INC</p>
                                <p>1720 Harrison ST STE 18B</p>
                                <p>Hollywood, FL, 33020, US</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PrivacyPolicyPage;
