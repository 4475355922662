import React, { Component } from 'react'
import { PhoneInput } from 'react-international-phone';

import './phoneInput.css';

export default class PhoneCustomInput extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <PhoneInput
        defaultCountry="us"
        inputStyle={{
          width: "100%",
          height: "66px",
          borderRadius: "0px 12px 12px 0px",
          border: "1px solid #CECED3",
          paddingLeft: "20px",
          fontSize: "18px",
          transition: "0.3s",
        }}
        countrySelectorStyleProps={{
          buttonStyle: {
            width: "100%",
            height: "66px",
            borderRadius: "12px 0px 0px 12px",
            border: "1px solid #CECED3",
            padding: "0px 12px",
            fontSize: "18px",
            transition: "0.3s",
          },
          dropdownStyleProps: {
            className: "phone-list__container",
            listItemClassName: "country__container",
          }
        }}
        value={this.props.value}
        onChange={this.props.onChange}
      />

    )
  }
}
