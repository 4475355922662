import React, { Component } from 'react'
import './fileInput.css';
import frontId from '../../../../static/img/frontId.svg'
import backId from '../../../../static/img/backId.svg'

export default class FileInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      fileName: "",
      inputRef: React.createRef(null),
    }
  }


  handleOnChange = (evt) => {
    if (evt.target.files && evt.target.files.length > 0) {
      this.setState({ file: URL.createObjectURL(evt.target.files[0]), fileName: evt.target.files[0].name });
      this.props.loadImage(evt, this.props.side)
    }
  }

  onChooseFile = () => {
    this.state.inputRef.current.click();
  }

  handleDragOver = (evt) => {
    evt.preventDefault();
  }

  handleDropFile = (evt) => {
    evt.preventDefault();
    console.log(evt.dataTransfer.files[0])
    this.setState({ file: URL.createObjectURL(evt.dataTransfer.files[0]), fileName: evt.dataTransfer.files[0].name });
    this.props.loadImage(evt, this.props.side, true)
  }


  render() {
    return (
      <div onDrop={this.handleDropFile} onDragOver={this.handleDragOver} className='file-input__container'>
        <input onChange={this.handleOnChange} className="file-input" type="file" accept="image/jpeg,image/png,image/gif" ref={this.state.inputRef}></input>
        {!this.state.file && <div className='file-input__column'>
          <img className='file-input__icon' src={this.props.side === "frontID" ? frontId : backId} alt='front'></img>
          <span className='file-input__title'>{`Photo showing ${this.props.side === "frontID" ? "front" : "back"} of ID card`}</span>
          <button className='submitButtonStepStyle file-input__button' onClick={this.onChooseFile}>Choose a file</button>
        </div>}
        {this.state.file && <div className='file-input__column'>
          <img className='file-input__image' src={this.state.file} alt='frontFile'></img>
          <span className='file-input__title'>{this.state.fileName}</span>
          <span onClick={this.onChooseFile} className='file-input__button_upload'>Upload a new file</span>
        </div>}

      </div>
    )
  }
}
