import React, { Component } from 'react';
import './termsOfUse.css';
import backIcon from '../../static/img/back.png';
import '../../static/css/bootstrap-grid.css';

class TermsOfUsePage extends Component {
    render() {
        return (
            <div className='container'>
                {/* <div className='backButtonPrivacy' onClick={() => this.props.Back()}><img src={backIcon} style={{ height: 15, cursor: "pointer" }} />Back</div> */}
                <div className='policy'>
                    <div>
                        <h1 className='policy__title'>Terms of Use</h1>
                        <p className='policy__date'>Effective Date: 05.07.24</p>
                    </div>
                    <div className='policy__rules'>
                        <div>
                            <h2 className='policy__rules_main-item'>1. Introduction</h2>
                            <p>Welcome to WhatMoney INC. By using our currency exchange services ("Services"), which allow you to submit a currency exchange request online and then collect the exchanged currency at our office in Miami, FL, USA, you agree to be bound by these Terms of Use ("Terms"). If you do not agree with any part of these Terms, you should discontinue use of our Services immediately.</p>
                        </div>
                        <div>
                            <h2 className='policy__rules_main-item'>2. Eligibility</h2>
                            <p>You must be at least 18 years old to use our Services. By agreeing to these Terms, you represent and warrant that you are of legal age to form a binding contract with WhatMoney INC.</p>
                        </div>
                        <div>
                            <h2 className='policy__rules_main-item'>3. Currency Exchange Process</h2>
                            <ul>
                                <li>Submission of Request: You may submit a request for currency exchange via our website specifying the amount and type of currency required.</li>
                                <li>Confirmation: Upon submission, you will receive a confirmation of your request with a transaction number, which you must bring along when collecting the currency.</li>
                                <li>Collection: You agree to collect the exchanged currency from our designated office within the time frame specified in the confirmation.</li>
                            </ul>
                        </div>
                        <div>
                            <h2 className='policy__rules_item'>4. Use of Services</h2>
                            <ul>
                                <li>License: WhatMoney INC grants you a limited, non-exclusive, non-transferable, and revocable license to use our Services strictly for personal and non-commercial currency exchange purposes.</li>
                                <li>Prohibited Activities: You are prohibited from using our Services for any unlawful activities, to cause harm or inconvenience to other users or WhatMoney INC, or to manipulate the currency exchange process.</li>
                            </ul>
                        </div>
                        <div>
                            <h2 className='policy__rules_item'>5. Accounts and Security</h2>
                            <ul>
                                <li>Account Responsibilities: You are responsible for all activities that occur under your account and for keeping your account password secure.</li>
                                <li>Account Security: Inform WhatMoney INC immediately if you suspect any unauthorized use of your account or any other breach of security.</li>
                            </ul>
                        </div>
                        <div>
                            <h2 className='policy__rules_item'>6. Intellectual Property</h2>
                            <p>All intellectual property rights in our Services are owned by or licensed to WhatMoney INC. Your use of the Services does not grant you ownership of any intellectual property in our Services.</p>
                        </div>
                        <div>
                            <h2 className='policy__rules_item'>7. Termination</h2>
                            <p>WhatMoney INC reserves the right to terminate or suspend your access to the Services without prior notice, particularly in case of your breach of these Terms.</p>
                        </div>
                        <div>
                            <h2 className='policy__rules_item'>8. Disclaimers</h2>
                            <p>Our Services are provided on an "AS IS" basis. WhatMoney INC does not guarantee that the Services will always be available, uninterrupted, or error-free.</p>
                        </div>
                        <div>
                            <h2 className='policy__rules_item'>9. Limitation of Liability</h2>
                            <p>WhatMoney INC is not liable for any direct, indirect, incidental, special, or consequential damages that may result from your use of our Services, including but not limited to financial losses arising from any exchange rate fluctuations.</p>
                        </div>
                        <div>
                            <h2 className='policy__rules_item'>10. Indemnification</h2>
                            <p>You agree to indemnify and hold harmless WhatMoney INC and its employees from any claims arising out of your use of the Services, your breach of these Terms, or your violation of any law or the rights of a third party.</p>
                        </div>
                        <div>
                            <h2 className='policy__rules_item'>11. Changes to Terms</h2>
                            <p>WhatMoney INC may revise these Terms at any time. You are advised to review the Terms periodically for any changes.</p>
                        </div>
                        <div>
                            <h2 className='policy__rules_item'>12. Governing Law</h2>
                            <p>These Terms are governed by the laws of the State of Florida, USA.</p>
                        </div>
                        <div>
                            <h2 className='policy__rules_item'>13. Contact Us</h2>
                            <p className='mb-4'>If you have questions about these Terms, please contact us at: <b>contact@what-money.miami</b></p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TermsOfUsePage;
