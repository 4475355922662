import './static/css/bootstrap-grid.css'
import AppMenu from './components/menu/menu';
import React, { Component } from 'react';
import Footer from './components/footer/footer';
import ExchangeHistory from './components/exchangeHistory/exchangeHistory';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage/PrivacyPolicyPage';
import TermsOfUsePage from './pages/TermsOfUsePage/TermsOfUsePage';

import { Switch, Route } from "react-router-dom";
import LoginPage from './pages/LoginPage/LoginPage';
import ForgotPassPage from './pages/ForgotPassPage/ForgotPassPage';
import RegistrationPage from './pages/RegistrationPage/RegistrationPage';
import { withRouter } from 'react-router-dom';
import ExchangePage from './pages/ExchangePage/ExchangePage';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import './App.css';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAllowedToCloseHistory: false,
      user: { auth: false },
      isHistoryOpened: false,
      historyCount: 0
    }
  }

  openExchangeHistory = () => {
    this.setState({ isHistoryOpened: true });
    setTimeout(() => this.setState({ isAllowedToCloseHistory: true }), 300);
  }

  isLogin = async () => {
    const api_url = await fetch(`user/islogin/`, {
      method: 'post',
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
      }
    });
    const data = await api_url.json();
    if (data['result']) {
      this.setState({ user: data["user"] })
      //this.props.history.push('/exchange');
    } else {
      // this.props.history.push('/');
    }
  }

  componentDidMount = () => {
    this.isLogin()
    if (this.state.user["auth"]) {
      this.props.history.push("/exchange")
    }
    document.title = "WhatMoney INC"
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      <div className="App">
        <ExchangeHistory
          User={this.state.user}
          isOpened={this.state.isHistoryOpened}
          isAllowedToCloseHistory={this.state.isAllowedToCloseHistory}
          onClose={(func) => {
            this.setState({ isHistoryOpened: false, isAllowedToCloseHistory: false });
            setTimeout(func, 300);
          }}
        />

        <div className='app__container'>
          <AppMenu
            historyCount={this.state.historyCount}
            setHistoryCount={(value) => this.setState({ historyCount: value })}
            openHistory={this.openExchangeHistory}
            User={this.state.user}
          />
          <Switch>
            <Route exact path="/">
              <LoginPage
                User={this.state.user}
                IsLogin={this.isLogin}
              />
            </Route>
            <Route exact path="/registration">
              <RegistrationPage
                User={this.state.user}
                IsLogin={this.isLogin}
              />
            </Route>
            <Route exact path="/forgot-password">
              <ForgotPassPage
                User={this.state.user}
                IsLogin={this.isLogin}
              />
            </Route>
            <Route exact path="/privacy-policy">
              <PrivacyPolicyPage />
            </Route>
            <Route path="/terms-of-use">
              <TermsOfUsePage />
            </Route>
            <Route exact path="/exchange">
              <ExchangePage
                User={this.state.user}
                setHistoryCount={(value) => this.setState({ historyCount: value })}
                openHistory={this.openExchangeHistory}
                historyCount={this.state.historyCount}
              />
            </Route>
            <Route path="*">
              <ErrorPage IsLogin={this.isLogin} />
            </Route>
          </Switch>
          <Footer User={this.state.user} />
        </div>

      </div>
    );
  }
}

export default withRouter(App);
