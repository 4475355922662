import React, { Component } from 'react';
import './footer.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export default class Footer extends Component {
  constructor(props) {
    super(props);
  }


  render() {
    return (
      <div>
        <footer id="footer">
          <div className="footer__container info_body">
            <p className="copyright">© 2024 WhatMoney INC. All right reserved.</p>
            <div className="info_link_wrap">
              <Link to="/privacy-policy"><p className="link">Privacy Policy</p></Link>
              <Link to="/terms-of-use"><p className="link">Terms of Use</p></Link>
            </div>
          </div>
        </footer>
      </div>
    )
  }
}
