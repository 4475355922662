import React, {Component} from 'react';
import '../../../../static/css/bootstrap-grid.css'
import './pointers.css'

class Pointers extends Component{
    constructor(props){
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div>
                <div className='d-none d-md-block'>
                    {
                        this.props.Steps.map((element) => 
                            <div className='elementStep row' key={this.props.Steps.indexOf(element) + 1}>
                                <div className='col-4' style={{display: "grid", justifyContent: "center"}}>
                                    {/* onClick={() => this.props.UpdateStep(element)} */}
                                    <div className={element === this.props.SetStep ? ['numberElementStep activeNumberElementStep'] : (this.props.Steps.indexOf(element) + 1) <=  (this.props.Steps.indexOf(this.props.SetStep) + 1) ? ['numberElementStep lastNumberElementStep'] : ['numberElementStep']}><span>{this.props.Steps.indexOf(element) + 1}</span></div>

                                    {
                                        this.props.Steps.indexOf(element) + 1 !== this.props.Steps.length ? 
                                        <div className='lineElementStep'>
                                            <div></div>
                                        </div> : ""
                                    }
                                    
                                </div>
                                <div className='col-6' style={{display: "flex", marginTop: "10px"}}>
                                    <div className={element === this.props.SetStep ? ['textElementSpan activeTextElementSpan'] : (this.props.Steps.indexOf(element) + 1) <=  (this.props.Steps.indexOf(this.props.SetStep) + 1) ? ['textElementSpan greyTextElementSpan'] : ['textElementSpan']}>{element}</div>
                                </div>
                            </div>
                        )
                    }
                </div>

                <div className='d-block d-md-none' style={{overflowX:"scroll"}}>
                    <div className='mobilePointer'>
                    {
                        this.props.Steps.map((element) => 
                            <div className='elementStep' key={this.props.Steps.indexOf(element) + 1}>
                                <div className={element === this.props.SetStep ? ['textElementSpan activeTextElementSpan'] : (this.props.Steps.indexOf(element) + 1) <=  (this.props.Steps.indexOf(this.props.SetStep) + 1) ? ['textElementSpan greyTextElementSpan'] : ['textElementSpan']}>{element}</div>
                            </div>
                        )
                    }
                    </div>
                </div>
            </div>
        );
    }
}
export default Pointers;