import React, { Component } from 'react';
import '../../static/css/bootstrap-grid.css'
import '../../core/core.css';
import InputCode from '../../components/inputCode/InputCode';
import PasswordStrengthIndicator from '../../components/passwordStrengthIndicator/PasswordStrengthIndicator';
import eyeIcon from '../../static/img/eye.svg';
import eyeSlashIcon from '../../static/img/eye-slash.svg';
import backIcon from '../../static/img/back.png';
import Loading from '../../components/loading/Loading';
import { withRouter } from 'react-router-dom';

class ForgotPassPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            confPass: "",
            code: "",
            codeStatus: "",
            step: "email",
            isVisiblePassword: false,
            isVisibleRepeat: false,
            error: "",
            passwordStrength: 0,
            loadingEmail: false,
            loadingCode: false,
            resendCode: false
        }
    }

    handleNavigateBack = () => {
        this.props.history.goBack();
    };

    sendEmailCode = async () => {
        this.setState({ loadingEmail: true });

        const api_url = await fetch(`user/getCodeMail/0/`, {
            method: 'post',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            },
            body: "email=" + this.state.email
        });
        const data = await api_url.json();

        this.setState({ loadingEmail: false });

        if (data['result']) {
            this.setState({ step: "code", error: "" });
            return;
        }
        this.setState({ error: "Such email doesn't exist! Register first." });
    }

    accessCode = async () => {
        this.setState({ loadingCode: true })
        const api_url = await fetch(`user/ifCodeMail/`, {
            method: 'post',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            },
            body: "email=" + this.state.email + "&code=" + this.state.code
        });
        const data = await api_url.json();

        this.setState({ loadingCode: false, codeStatus: data['result'] })

        if (data['result']) {
            this.setState({ step: "newPass" });
        }
    }


    setNewPassword = async () => {
        console.log(this.state.password, this.state.confPass);
        if (this.state.password !== this.state.confPass) {
            this.setState({ error: "Passwords do not match" })
            return;
        }

        const api_url = await fetch(`user/setNewPassword/`, {
            method: 'post',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            },
            body: "email=" + this.state.email + "&code=" + this.state.code + "&password=" + this.state.password
        });
        const data = await api_url.json();

        if (data['result']) {
            this.props.IsLogin();
            this.props.history.push('/exchange');
        }
    }

    validateEmail = (str) => {
        const emailRegex = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/);

        return !emailRegex.test(str);
    }

    validatePassword = (password) => {
        const hasUppercase = /[A-Z]/.test(password);
        const hasNumber = /[0-9]/.test(password);
        const minLength = password.length >= 9;
        return hasUppercase && hasNumber && minLength;
    };

    componentDidMount() {
        if (this.props.User['auth']) {
            this.props.history.goBack();
        }
    }

    render() {
        if (this.state.step === "email") {
            return (
                <div className='container loginContainer'>
                    <div className='loginBlock'>
                        <div className='container'>
                            <div>
                                <p onClick={this.handleNavigateBack}><img src={backIcon} style={{ height: 15, cursor: "pointer" }} /> Back</p>
                                <h1>Forgot Password</h1>
                                <p>Enter your Email and we will send you a verification code.</p>

                                <div className='inputLogin' style={{ marginBottom: 35 }}>
                                    <p>Email</p>
                                    <input maxLength="30" type="email" placeholder="Enter your email" onChange={(evt) => { this.setState({ email: evt.target.value.toLowerCase(), error: "" }) }}></input>
                                    <span className='incorrect-code'>{this.state.error}</span>
                                </div>
                            </div>

                            {this.state.loadingEmail ? <div className='row justify-content-center'><Loading /></div> : <button disabled={this.validateEmail(this.state.email)} className='submitButtonLoginStyle' onClick={() => this.sendEmailCode()}>Next</button>}

                        </div>
                    </div>
                </div>
            );
        }

        if (this.state.step === "code") {
            return (
                <div className='container loginContainer'>
                    <div className='loginBlock'>
                        <div className='container'>
                            <p onClick={() => this.setState({ step: "email" })}><img src={backIcon} style={{ height: 15, cursor: "pointer" }} /> Back</p>
                            <h1>Forgot Password</h1>
                            <p style={{ whiteSpace: "pre-line" }}>We’ve sent an email with verification code to: {this.state.email} {'\n'} If you don’t see it, please check your spam folder.</p>

                            <div className='inputLogin' style={{ marginBottom: 35 }}>
                                <p>Please enter the code sent to verify your email address</p>
                                <InputCode codeStatus={this.state.codeStatus} code={this.state.code} setCode={(evt) => this.setState({ code: evt })} />
                                {this.state.codeStatus === false && <span className='incorrect-code'>Incorrect code</span>}
                                {(this.state.codeStatus !== true && this.state.loadingCode === false) && <div onClick={() => {
                                    this.sendEmailCode();
                                    this.setState({ resendCode: true, codeStatus: "" });
                                }} className='forgotPass__resend-code'>Resend code</div>}
                                {this.state.resendCode === true && this.state.codeStatus === "" && <span className='code-sent'>Code sent succesfully</span>}
                            </div>

                            {this.state.loadingCode ? <div className='row justify-content-center'><Loading /></div> : <button disabled={this.state.code.length < 6} className='submitButtonLoginStyle' onClick={() => this.accessCode()}>Next</button>}

                        </div>
                    </div>
                </div>
            );
        }

        if (this.state.step === "newPass") {
            return (
                <div className='container loginContainer'>
                    <div className='loginBlock'>
                        <div className='container'>
                            <div>
                                <h1>Set New Password</h1>
                                <p style={{ fontSize: "12px", marginTop: "0px" }}>The password must include at least one uppercase letter and one number, and be a minimum of 9 characters in length.</p>
                                <div className='inputLogin'>
                                    <p>New password</p>
                                    <div className='inputLogin__container'>
                                        <input maxLength="30" type={`${this.state.isVisiblePassword ? "text" : "password"}`} placeholder="Enter your password" onChange={(evt) => { this.setState({ password: evt.target.value, error: "" }) }}></input>
                                        <img onClick={() => this.setState({ isVisiblePassword: !this.state.isVisiblePassword })} src={this.state.isVisiblePassword ? eyeSlashIcon : eyeIcon} alt='eye'></img>
                                    </div>
                                    <PasswordStrengthIndicator setPasswordStrength={(value) => this.setState({ passwordStrength: value })} password={this.state.password} />
                                </div>

                                <div className='inputLogin'>
                                    <p>Confirm New password</p>
                                    <div className='inputLogin__container'>
                                        <input style={this.state.error.length > 0 ? {
                                            border: "1px solid #DE5656"
                                        } : {}} maxLength="30" type={`${this.state.isVisibleRepeat ? "text" : "password"}`} placeholder="Enter your password" onChange={(evt) => { this.setState({ confPass: evt.target.value, error: "" }) }}></input>
                                        <img onClick={() => this.setState({ isVisibleRepeat: !this.state.isVisibleRepeat })} src={this.state.isVisibleRepeat ? eyeSlashIcon : eyeIcon} alt='eye'></img>
                                        <span className='incorrect-code'>{this.state.error}</span>
                                    </div>

                                </div>
                            </div>
                            <button disabled={!this.validatePassword(this.state.password) || this.state.passwordStrength < 1 || this.state.confPass === "" || this.state.password.length < 9 || this.state.confPass.length < 9} className='submitButtonLoginStyle' style={{ marginTop: "22px" }} onClick={() => this.setNewPassword()}>Set Password</button>

                        </div>
                    </div>
                </div >
            );
        }
    }
}

export default withRouter(ForgotPassPage);