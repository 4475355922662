import React, { Component } from 'react';
import chevronDown from '../../static/img/chevron-down.svg';
import cross from '../../static/img/cross.svg';

export default class ExchangeHistoryFilters extends Component {
  constructor(props) {
    super(props);
    this.dateArr = ["Today", "Last 7 days", "Last 30 days", "Last 90 days", "Last year"];
    this.statusArr = ["Pending", "Declined", "Done"];
    this.currencyArr = ["USD → USDT", "USDT → USD"];
    this.state = {
      isDateOpen: false,
      isStatusOpen: false,
      isCurrencyOpen: false,
      dateRef: React.createRef(null),
      statusRef: React.createRef(null),
      currencyRef: React.createRef(null),
    }
  }

  handleClickOutside = (evt) => {
    if (this.state.isDateOpen && this.state.dateRef && this.state.dateRef.current && !this.state.dateRef.current.contains(evt.target)) {
      this.setState({ isDateOpen: false });
    } else if (this.state.isStatusOpen && this.state.statusRef && this.state.statusRef.current && !this.state.statusRef.current.contains(evt.target)) {
      this.setState({ isStatusOpen: false });
    } else if (this.state.isCurrencyOpen && this.state.currencyRef && this.state.currencyRef.current && !this.state.currencyRef.current.contains(evt.target)) {
      this.setState({ isCurrencyOpen: false });
    } else if (this.state.isSettingsOpen && this.state.settingsRef && this.state.settingsRef.current && !this.state.settingsRef.current.contains(evt.target)) {
      this.setState({ isSettingsOpen: false });
    }
  }

  componentDidMount() {
    document.body.addEventListener("click", this.handleClickOutside);

    return () => {
      document.body.addEventListener("click", this.handleClickOutside)
    };
  }


  render() {
    return (
      <div onClick={this.props.onClick} className='exchange-history__filters'>
        <div className='filter__container date'>
          <div ref={this.state.dateRef} className='input__container'>
            {this.props.chosenDate ? <img onClick={() => this.props.setChosenDate("")} className='cross' src={cross} alt='cross'></img> : this.state.isDateOpen ? <img onClick={() => this.setState({ isDateOpen: false })} className='rotate' src={chevronDown} alt='chevronUp'></img> : <img onClick={() => this.setState({ isDateOpen: true })} src={chevronDown} alt='chevronDown'></img>}
            <input value={this.props.chosenDate.length <= 11 ? this.props.chosenDate : `${this.props.chosenDate.slice(0, 9)}...`} onClick={() => this.setState({ isDateOpen: true })} className='filter__input' readOnly placeholder='Date'></input>
          </div>
          {this.state.isDateOpen && <div className='filter__options date'>
            {this.dateArr.map(item => <div key={item} onClick={() => this.props.setChosenDate(item)} className={`filter__item ${this.props.chosenDate === item ? "chosen" : ""}`}>{item}</div>)}
          </div>}
        </div>
        <div className='filter__container status'>
          <div ref={this.state.statusRef} className='input__container'>
            {this.props.chosenStatus ? <img onClick={() => this.props.setChosenStatus("")} className='cross' src={cross} alt='cross'></img> : this.state.isStatusOpen ? <img onClick={() => this.setState({ isStatusOpen: false })} className='rotate' src={chevronDown} alt='chevronUp'></img> : <img onClick={() => this.setState({ isStatusOpen: true })} src={chevronDown} alt='chevronDown' style={{ cursor: "pointer" }}></img>}
            <input value={this.props.chosenStatus.length <= 11 ? this.props.chosenStatus : `${this.props.chosenStatus.slice(0, 9)}...`} onClick={() => this.setState({ isStatusOpen: true })} className='filter__input' readOnly placeholder='Status'></input>
          </div>
          {this.state.isStatusOpen && <div className='filter__options status'>
            {this.statusArr.map(item => <div key={item} onClick={() => this.props.setChosenStatus(item)} className={`filter__item ${this.props.chosenStatus === item ? "chosen" : ""}`}>{item}</div>)}
          </div>}
        </div>
        <div className='filter__container currency'>
          <div ref={this.state.currencyRef} className='input__container'>
            {this.props.chosenCurrency ? <img onClick={() => this.props.setChosenCurrency("")} className='cross' src={cross} alt='cross'></img> : this.state.isCurrencyOpen ? <img onClick={() => this.setState({ isCurrencyOpen: false })} className='rotate' src={chevronDown} alt='chevronUp'></img> : <img onClick={() => this.setState({ isCurrencyOpen: true })} src={chevronDown} alt='chevronDown' style={{ cursor: "pointer" }}></img>}
            <input value={this.props.chosenCurrency.length <= 8 ? this.props.chosenCurrency : `${this.props.chosenCurrency.slice(0, 8)}...`} onClick={() => this.setState({ isCurrencyOpen: true })} className='filter__input' readOnly placeholder='Currency'></input>
          </div>
          {this.state.isCurrencyOpen && <div className='filter__options currency'>
            {this.currencyArr.map(item => <div key={item} onClick={() => this.props.setChosenCurrency(item)} className={`filter__item ${this.props.chosenCurrency === item ? "chosen" : ""}`}>{item}</div>)}
          </div>}
        </div>
      </div>
    )
  }
}
