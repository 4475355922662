export const fromStringToFloat = (value) => {
  if (typeof value !== "string") {
    return value;
  }
 
  const result = value.replaceAll('.', '').replaceAll(',', '.');
  return parseFloat(result);
}

export const formatNumber = (value) => {
  const tempValue = fromStringToFloat(value);

  return tempValue.toLocaleString("de-DE", { minimumFractionDigits: 2, maximumFractionDigits: 2, });
}

export const formatCountToString = (value) => {
  const tempValue = parseFloat(value);

  return tempValue.toLocaleString("de-DE", { minimumFractionDigits: 2, maximumFractionDigits: 2, });
}

export const formatCountToFloat = (value) => {
  const tempValue = parseFloat(value);

  return Math.round(tempValue * 100) / 100;
}


