import '../../static/css/bootstrap-grid.css'
import React, { Component } from 'react';
import '../../core/core.css';
import { NumericFormat } from 'react-number-format';
import { formatNumber, fromStringToFloat } from '../../utils/priceFunctions';

class InputCurrency extends Component {
  constructor(props) {
    super(props);
  }

  setValCurransy = (value) => {
    if (!value) {
      this.props.SetValue(0);
      return;
    }


    this.props.SetValue(fromStringToFloat(value))
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.invert !== this.props.invert) {
      this.setValCurransy(this.props.InitValue)
    }
  }

  render() {
    return (
      <div className='inputCurrency'>
        <h3>{this.props.Header}</h3>
        <div className='inputCurrency__row'>
          <div className='inputCurrency__value'>
            {
              this.props.Active ?
                <NumericFormat
                  decimalScale={2}
                  allowNegative={false}
                  allowLeadingZeros={false}
                  fixedDecimalScale
                  placeholder={"0,00"}
                  thousandSeparator="."
                  decimalSeparator=","
                  value={this.props.InitValue}
                  isAllowed={(values) => {
                    const { floatValue } = values;
                    if (!floatValue) {
                      return true;
                    }

                    return floatValue <= 1000000000 && floatValue >= 1;
                  }}
                  onChange={(e) => {
                    this.setValCurransy(e.target.value);
                  }} />
                :
                <input readOnly value={formatNumber(this.props.InitValue)}></input>
            }
          </div>
          <div style={{ justifyContent: "right", display: "flex", alignItems: "center" }}>
            <img src={this.props.LogoCurrancy} />
          </div>
        </div>
      </div>
    );
  }
}

export default InputCurrency;