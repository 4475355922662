import React, { Component } from 'react'
import './loading.css';
import loadingIcon from '../../static/img/loading.svg';

export default class Loading extends Component {
  render() {
    return (
      <div className='rotating-circle'>
        <img src={loadingIcon} alt='loading'></img>
      </div>
    )
  }
}
