import React, { Component } from 'react';
import FileInput from '../fileInput/FileInput';

import '../../../../static/css/bootstrap-grid.css'
import './step.css'

class IDuploadStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image1: "",
            image2: ""
        }
    }

    loadImage = async (evt, typeID, isDragged) => {
        const uploadData = new FormData()
        if (isDragged) {
            uploadData.append('image', evt.dataTransfer.files[0], evt.dataTransfer.files[0].name)
            uploadData.append('userInfo', JSON.stringify(this.props.UserInfo))
        } else {
            uploadData.append('image', evt.target.files[0], evt.target.files[0].name)
            uploadData.append('userInfo', JSON.stringify(this.props.UserInfo))
        }

        uploadData.append('type', typeID)
        const api_url = await fetch(`/user/addUserIDImage/`, {
            method: 'post',
            body: uploadData
        });
        const data = await api_url.json();
        if (data['result']) {
            if (typeID === "frontID") {
                this.setState({ image1: data['url'] })
            }
            if (typeID === "backID") {
                this.setState({ image2: data['url'] })
            }
        }
    }

    render() {
        return (
            <div className='StepBlock' style={{ height: "100%" }}>
                <div className='StepBlockHeader'>
                    <div className='stepNumber d-none d-md-block'>Step {this.props.Steps.indexOf(this.props.SetStep) + 1}<span>/{this.props.Steps.length}</span></div>
                    <h2>Selfie with ID upload</h2>
                    <p>Please upload two pictures of yourself holding your ID card. Your face should be visible in both.</p>
                </div>
                <div className='StepBlockMain'>
                    <div className='row' style={{ paddingBottom: '30px' }}>

                        <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 mb-4'>
                            <p className='id__info-text'>This photo should show the front of your ID card below your face.</p>
                            <FileInput loadImage={this.loadImage} side={"frontID"} />
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12'>
                            <p className='id__info-text'> This photo should show the back of your ID card below your face. </p>
                            <FileInput loadImage={this.loadImage} side={"backID"} />
                        </div>

                    </div>
                    <div className='row' style={{ justifyContent: "right" }}>
                        <button disabled={this.state.image1 === "" || this.state.image2 === ""} className='submitButtonStepStyle' onClick={() => { let info = this.props.UserInfo; info.step = 'Password'; this.props.UpdateUser(info) }}>Next step</button>
                    </div>
                </div>
            </div>
        );
    }
}
export default IDuploadStep;