import './radioButton.css';

const RadioButton = ({name, text, checked, onChange, value}) => {
  return (
    <div className='radio-button'>
      <label className='radio-button__label'>
        <input value={value} onChange={onChange} checked={checked} name={name} type='radio'></input>
        <span>{text}</span>
      </label>
    </div>
  )
}

export default RadioButton;