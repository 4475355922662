import React, { Component } from 'react';
import '../../static/css/bootstrap-grid.css';
import './errorPage.css';
import bigLogo from '../../static/img/bigLogo.svg';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';

class ErrorPage extends Component {
    render() {
        return (
            <div className='container error-page'>
                <div className='error-page__title'>
                    <span>4</span><img src={bigLogo} alt='bigLogo'></img><span>4</span>
                </div>
                <p className='error-page__text'>Sorry, this page isn't available.</p>
                <button onClick={() => this.props.history.goBack()} className='error-page__button'>Back to previous page</button>
            </div>
        )
    }
}

export default withRouter(ErrorPage);
