import React, { Component } from 'react';

import snsWebSdk from '@sumsub/websdk';
import '../../../../core/core.css'
import '../../../../static/css/bootstrap-grid.css'
import '../stepScreens/step.css'
import RadioButton from '../../../../components/RadioButton/RadioButton';
import { INSTORE, ONLINE } from '../../../../static/constants/radio';


class KycTest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newAccessToken: false,
            check: true,
            stepKyc: "",
            userInfo: {},
        }
    }

    getNewAccessToken = () => {
        return Promise.resolve(this.state.newAccessToken); // get a new token from your backend
    }

    launchWebSdk = (accessToken, applicantEmail, applicantPhone, customI18nMessages) => {
        let snsWebSdkInstance = snsWebSdk
            .init(
                accessToken,
                // token update callback, must return Promise
                // Access token expired
                // get a new one and pass it to the callback to re-initiate the WebSDK
                () => this.getNewAccessToken()
            )
            .withConf({
                lang: "en", //language of WebSDK texts and comments (ISO 639-1 format)
                email: applicantEmail,
                phone: applicantPhone,
                adaptIframeHeight: true,
                theme: "light",
                // uiConf: {
                //     customCssStr: ".SdkHeader:{display: none !important;}"
                // },
            })
            .withOptions({ adaptIframeHeight: true })
            // see below what kind of messages WebSDK generates


            .on("idCheck.onStepInitiated", (evt) => {
                // треба відправляти стейт на сервак та перевіряти чи ок з поштою та номером користувача
                this.getStatusKycReg()
                this.setState({ stepKyc: evt.idDocSetType })
                // Відправляємо перевірку минулого стему та якщо погано змінюємо назад степ, якщо гуд то наступний. Тобто якщо користувач існує відображаємо екран по новій
            })
            // .on("idCheck.onStepCompleted", (payload) => {
            //     //this.getInfoAplicant()
            //     //console.log(payload)
            //     console.log("step Complite")
            //     //console.log("onStepCompleted", payload);
            // })


            .on("idCheck.onError", (error) => {
                //this.getInfoAplicant()
                console.log("onError", error);
            })
            .on("idCheck.onApplicantStatusChanged", (evt) => {
                this.getInfoAplicant()
            })
            .build();

        // you are ready to go:
        // just launch the WebSDK by providing the container element for it
        snsWebSdkInstance.launch("#sumsub-websdk-container");
    }

    getStatusKycReg = async () => {
        const api_url = await fetch(`/user/getBlockInfoKyc/`, {
            method: 'post',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            },
            body: "step=" + this.state.stepKyc
        });
        const data = await api_url.json();
        if (data['result']) {
            alert(data['massege'])
        }
    }

    getNewToken = async () => {
        const api_url = await fetch(`/user/getAccessKycToken/`, {
            method: 'post',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            }
        });
        const data = await api_url.json();
        if (data['result']) {
            // this.setState({newAccessToken: data['token']})
            this.launchWebSdk(data['token'])

            // intervalUpdate = setInterval(this.getInfoAplicant, 3000)
            // this.setState({intervalUpdate: setInterval(this.getInfoAplicant, 3000)})

            // this.setState({interval: setInterval(this.getInfoAplicant, 3000)})
        }
    }

    getInfoAplicant = async () => {
        const api_url = await fetch(`/user/getInfoAplicant/`, {
            method: 'post',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            }
        });
        const data = await api_url.json();
        console.log(data)
        if (data['result']) {
            // clearInterval(this.state.intervalUpdate)
            // this.setState({ check: false, userInfo: data['userInfo'] })

            let info = this.props.UserInfo;
            info.codeMail = this.state.code;
            info.step = this.props.Steps[1];
            if (data['userInfo']['firstName'] !== "") { info.firstName = data['userInfo']['firstName'] }
            if (data['userInfo']['lastName'] !== "") { info.lastName = data['userInfo']['lastName'] }
            if (data['userInfo']['email'] !== "") { info.email = data['userInfo']['email'] }
            if (data['userInfo']['phone'] !== "") { info.phone = data['userInfo']['phone'] }
            if (data['userInfo']['country'] !== "") { info.country = data['userInfo']['country'] }
            if (data['userInfo']['birth'] !== "") { info.birth = data['userInfo']['birth'] }
            this.props.UpdateUser(info);
        }
    }

    handleRadioChange = (e) => {
        this.props.updateRegType(e.target.value);
    }

    // додати фукцію яка оновлює дані після завантаження на сервер і оновлює наступний степ. Переробити персонал та додати редагуємі та не редагуємі поля

    componentDidMount = () => {
        this.getNewToken()
    }

    render() {
        return (
            <div className='StepBlock'>
                <div className='StepBlockHeader'>
                    <div className='stepNumber d-none d-md-flex justify-content-md-between'>
                        <p>Step {this.props.Steps.indexOf(this.props.SetStep) + 1}<span>/{this.props.Steps.length}</span></p>
                        <p>Application form</p>
                    </div>
                    <h2>Verification</h2>
                    <p>Please complete the verification process via KYC.</p>
                </div>

                <div className='StepBlockMain' style={{padding: "0px 40px"}}>
                    <p>Choose the type of registration</p>
                    <div className='radio-buttons'>
                        <RadioButton onChange={this.handleRadioChange} value={INSTORE} checked={this.props.regType === INSTORE} name="type" text={INSTORE}/>
                        <RadioButton onChange={this.handleRadioChange} value={ONLINE} checked={this.props.regType === ONLINE} name="type" text={ONLINE}/>
                    </div>
                    <div id="sumsub-websdk-container" className='sumsub-container'></div>
                    {/* <button className='submitButtonStepStyle' onClick={() => this.getInfoAplicant()}>Check</button> */}
                    {/* <div className='row' style={{ justifyContent: "right" }}>
                        <button className='submitButtonStepStyle' disabled={this.state.check} onClick={() => {
                            let info = this.props.UserInfo;
                            info.codeMail = this.state.code;
                            info.step = this.props.Steps[this.props.Steps.indexOf(this.props.UserInfo.step) + 1];
                            if (this.state.userInfo['firstName'] !== "") { info.firstName = this.state.userInfo['firstName'] }
                            if (this.state.userInfo['lastName'] !== "") { info.lastName = this.state.userInfo['lastName'] }
                            if (this.state.userInfo['email'] !== "") { info.email = this.state.userInfo['email'] }
                            if (this.state.userInfo['phone'] !== "") { info.phone = this.state.userInfo['phone'] }
                            if (this.state.userInfo['country'] !== "") { info.country = this.state.userInfo['country'] }
                            this.props.UpdateUser(info);
                        }}>Next</button>
                    </div> */}

                </div>

            </div>
        );
    }
}
export default KycTest;