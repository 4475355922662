import './style.css';

const CurrencySelectItem = ({ text, image1, image2, onClick, isChosen }) => {
    return (
        <div onClick={onClick} className={`currency-select-item ${isChosen ? "chosen" : ""}`}>
            <div className='currency-select-item__images'>
                {image1 && <img src={image1} alt='image1'></img>}
                {image2 && <img src={image2} alt='image2'></img>}
            </div>
            {text && <span className='currency-select-item__text'>{text}</span>}
        </div>
    )
}

export default CurrencySelectItem