import React, { Component } from 'react'
import zxcvbn from 'zxcvbn';

import './strengthIndicator.css';

export default class PasswordStrengthIndicator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: ""
    }
  }

  checkPasswordStrength = (lineNum) => {
    const score = zxcvbn(this.props.password).score;
    let color = "#E5E4E7";
    switch (score) {
      case 0:
        color = "#DE5656";
        break;
      case 1:
        color = "#DE9756";
        break;
      case 2:
        color = "#DEC856";
        break;
      case 3:
        color = "#6ACF46";
        break;
      case 4:
        color = "#299F66";
        break;
      default:
        color = "#E5E4E7";
    }

    if (this.props.password.length == 0) {
      return "#E5E4E7";
    } else if (score >= lineNum) {
      return color;
    }

    return "#E5E4E7";
  }

  showPasswordStatus = () => {
    const score = zxcvbn(this.props.password).score;
    let status = "Weak 😞";

    switch (score) {
      case 0:
        status = "Weak 😞";
        break;
      case 1:
        status = "Easy 🤫";
        break;
      case 2:
        status = "Fine 👌";
        break;
      case 3:
        status = "Good 😀";
        break;
      case 4:
        status = "Excellent 🦾";
        break;
      default:
        status = "Weak 😞";
    }

    if (this.props.password.length == 0) {
      status = "";
    }

    if (this.props.setPasswordStrength) {
      this.props.setPasswordStrength(score);
    }

    this.setState({ status: status });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.password !== this.props.password) {
      this.showPasswordStatus();
    }
  }


  render() {
    return (
      <div className='col'>
        <div className='row g-2' style={{padding: "4px 0px"}}>
          <div className='col mx-1 indicator' style={{
            backgroundColor: this.checkPasswordStrength(0)
          }}></div>
          <div className='col mx-1 indicator' style={{
            backgroundColor: this.checkPasswordStrength(1)
          }}></div>
          <div className='col mx-1 indicator' style={{
            backgroundColor: this.checkPasswordStrength(2)
          }}></div>
          <div className='col mx-1 indicator' style={{
            backgroundColor: this.checkPasswordStrength(3)
          }}></div>
          <div className='col mx-1 indicator' style={{
            backgroundColor: this.checkPasswordStrength(4)
          }}></div>
        </div>
        <div className='row justify-content-end'>
          <span className='strength-status'>{this.state.status}</span>
        </div>
      </div>
    )
  }
}
