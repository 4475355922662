import React, { Component } from 'react';
import '../../../static/css/bootstrap-grid.css'
import './logo.css'
import logoIcon from "../../../static/img/logo.svg"

class Logo extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div onClick={this.props.onClick} className='logo'><img src={logoIcon} alt="Logo" /></div>
        );
    }
}
export default Logo;