import React, { Component } from 'react'
import OtpInput from 'react-otp-input';

import './code.css';

export default class InputCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codeColor: ""
    }
  }

  componentDidUpdate = (nextProps) => {
    if (nextProps.codeStatus !== this.props.codeStatus) {
      this.props.codeStatus ? this.setState({ codeColor: "#4FB54D" }) : this.setState({ codeColor: "#DE5656" })
    }
  }

  render() {
    return (
      <OtpInput
        skipDefaultStyles={true}
        value={this.props.code}
        containerStyle="otp-input__container"
        inputType="tel"
        onChange={this.props.setCode}
        numInputs={6}
        renderInput={(inputProps) => <input name="otp-code" {...inputProps} style={{
          border: `1px solid ${this.state.codeColor}`,
          padding: 0
        }} />}
      />
    )
  }
}
