import React, { Component } from 'react';
import Pointers from './components/pointers/pointers';
import '../../static/css/bootstrap-grid.css';
import './registration.css';

import InitialsStep from './components/stepScreens/InitialsStep';
import EmailStep from './components/stepScreens/EmailStep';
import PhoneStep from './components/stepScreens/PhoneStep';
import PersonalStep from './components/stepScreens/PersonalStep';
import FinancialStep from './components/stepScreens/FinancialStep';
import IDuploadStep from './components/stepScreens/IDuploadStep';
import PasswordStep from './components/stepScreens/PasswordStep';
import SubmitStep from './components/stepScreens/SubmitStep';
import KycTest from './components/kyc/kyc';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { INSTORE } from '../../static/constants/radio';
// import PrivacyPolicy from './components/policies/privacyPolicy';

class RegistrationPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            policy: false,
            steps: ["Verification", "Personal", "Financial", "Password", "Submit"],
            userInfo: {
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                birth: "",
                nationality: "",
                citizenship: "",
                country: "",
                adress: "",
                currentEmployer: "",
                occupation: "",
                SSN: "",
                frontID: "",
                backID: "",
                password: "",
                conditions: false,
                step: "KYC",
                codeMail: "",
                codePhone: "",
                typeSSN: "SIN",
                regType: ""
            },
            regType: INSTORE,
        }
    }

    updateDataUser = (info) => {
        this.setState({ userInfo: info })
    }

    componentDidMount = () => {
        if (this.props.User['auth']) {
            this.props.history.push('/exchange');
        }

        let user = this.state.userInfo
        user.step = this.state.steps[0]
        this.setState({ userInfo: user })

    }

    updateUser = async (info) => {
        this.setState({ userInfo: info })
        const api_url = await fetch(`user/registrate/`, {
            method: 'post',
            headers: {
                "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
            },
            body: 'userInfo=' + JSON.stringify(info)
        });
        const data = await api_url.json();
        if (data['result'] && info.step === "Login") {
            this.props.IsLogin()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.User !== this.props.User) {
            if (this.props.User['auth']) {
                this.props.history.push('/exchange');
            }
        }
    }

    render() {
        return (
            <div className='container regContainer'>
                {/* {
                    this.state.policy ? <PrivacyPolicy Back={() => this.setState({ policy: false })} /> : ""
                } */}
                <div className='row' style={this.state.policy ? { display: "none" } : {}}>
                    <div className='regHeader col-12 d-md-none'>
                        <h2>Application Form</h2>
                        <p>Thank you for your interest.</p>
                        <p>To apply, please complete this form in English.</p>
                    </div>

                    <div className='col-12 mobileRegBlock'>
                        <div className='mainRegBlock'>

                            <div className='col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12 pointerBlock'>
                                {/* Компонент показчик стадії */}
                                {/* Зроблено для тестування можливість переключення між степами */}
                                <Pointers Steps={this.state.steps} SetStep={this.state.userInfo.step} UpdateStep={(value) => { let user = this.state.userInfo; user.step = value; this.setState({ userInfo: user }) }} />
                            </div>

                            <div className='col-lg-9 col-md-9 col-sm-12 col-xs-12 col-12' style={{ padding: "0px" }}>

                                {/* Тестові інтеграції */}
                                {this.state.userInfo.step === "Verification" ? <KycTest regType={this.state.regType} updateRegType={(value) => this.setState({ regType: value })} Steps={this.state.steps} SetStep={this.state.userInfo.step} UserInfo={this.state.userInfo} UpdateUser={this.updateUser} /> : ""}


                                {/* Компонент ітерації */}
                                {this.state.userInfo.step === "Initials" ? <InitialsStep UpdateDataUser={this.updateDataUser} UserInfo={this.state.userInfo} Steps={this.state.steps} SetStep={this.state.userInfo.step} /> : ""}
                                {this.state.userInfo.step === "Email" ? <EmailStep SetCodeMail={(code) => this.setState({ codeMail: code })} UpdateDataUser={this.updateDataUser} UpdateUser={this.updateUser} UserInfo={this.state.userInfo} Steps={this.state.steps} SetStep={this.state.userInfo.step} /> : ""}
                                {this.state.userInfo.step === "Phone" ? <PhoneStep SetCodePhone={(code) => this.setState({ codePhone: code })} UpdateDataUser={this.updateDataUser} UpdateUser={this.updateUser} UserInfo={this.state.userInfo} Steps={this.state.steps} SetStep={this.state.userInfo.step} /> : ""}
                                {this.state.userInfo.step === "Personal" ? <PersonalStep Policy={() => this.setState({ policy: true })} UpdateDataUser={this.updateDataUser} UpdateUser={this.updateUser} UserInfo={this.state.userInfo} Steps={this.state.steps} SetStep={this.state.userInfo.step} /> : ""}
                                {this.state.userInfo.step === "Financial" ? <FinancialStep Policy={() => this.setState({ policy: true })} UpdateDataUser={this.updateDataUser} UpdateUser={this.updateUser} UserInfo={this.state.userInfo} Steps={this.state.steps} SetStep={this.state.userInfo.step} /> : ""}
                                {this.state.userInfo.step === "ID upload" ? <IDuploadStep UpdateDataUser={this.updateDataUser} UpdateUser={this.updateUser} UserInfo={this.state.userInfo} Steps={this.state.steps} SetStep={this.state.userInfo.step} /> : ""}
                                {this.state.userInfo.step === "Password" ? <PasswordStep UpdateDataUser={this.updateDataUser} UpdateUser={this.updateUser} UserInfo={this.state.userInfo} Steps={this.state.steps} SetStep={this.state.userInfo.step} /> : ""}
                                {this.state.userInfo.step === "Submit" ? <SubmitStep regType={this.state.regType} IsLogin={this.props.IsLogin} UpdateDataUser={this.updateDataUser} UpdateUser={this.updateUser} UserInfo={this.state.userInfo} Steps={this.state.steps} SetStep={this.state.userInfo.step} /> : ""}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(RegistrationPage);