import React, { Component } from 'react';
import '../../../static/css/bootstrap-grid.css'
import './buttons.css'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';


class ButtonMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className='buttonMenu' onClick={() => this.props.history.push('/')}>
                {this.props.Name}
            </div>
        );
    }
}
export default withRouter(ButtonMenu);