import { useEffect, useRef, useState } from 'react';
import './style.css';
import downArrow from '../../static/img/arrow-roll-bottom.svg';

const CustomSelect = ({ disabled, label, options, value, placeholder }) => {

    const [isOpened, setIsOpened] = useState(false);
    const ref = useRef(null);


    useEffect(() => {
        const handleClick = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsOpened(false);
            }
        };

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    useEffect(() => {
        setIsOpened(false);
    }, [value]);

    return (
        <div ref={ref} className='custom-select__container'>
            <div className='custom-select__label'>
                <span>{label}</span>
                <div onClick={() => setIsOpened(prevValue => !prevValue)} className='custom-select' disabled={disabled}>
                    {!value && <span className='custom-select__placeholder'>{placeholder}</span>}
                    <div className='custom-select__value'>
                        <div className='custom-select__images'>
                            {value?.image1 && <img className='custom-select__images_img1' src={value.image1} alt='image1'></img>}
                            {value?.image2 && <img className='custom-select__images_img2' src={value.image2} alt='image2'></img>}
                        </div>
                        {value?.text && <span className='custom-select__text'>{value.text}</span>}
                    </div>

                    <img className={`${(isOpened && !disabled) ? "imgRotate" : ""}`} src={downArrow} alt='downArrow'></img>
                </div>
            </div>
            {isOpened && !disabled && <div className='custom-select__items'>
                {options.map(item => item)}
            </div>}
        </div>
    )
}

export default CustomSelect;